import types from "./types";
import sharedTypes from "api/item-create/types";

export const INITIAL_STATE = {
  fetchedCategories: [],
  selectedCategory: [],
  createItemResponse: {},
  errorMessage: {},
  errorMessageGpc: {},
  selectedTags: [],
  itemDisplayName: "",
  itemDescription: "",
  uploadCoverPhotoLoading: false,
  coverPhotoLink: "",
  uploadDisplayPictureLoading: false,
  displayPictureLink: "",
  errorUploadImage: "",
  erroruploadDisplayPicture: "",
  itemCreate: false,
  gtin: "",
  shareData: {},
  serialNumber: "",
};

const inventoryCreateItemReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case sharedTypes.RECEIVE_ITEM_CATEGORIES: {
      return {
        ...state,
        fetchedCategories: action.payload,
      };
    }
    case sharedTypes.RECEIVE_ITEM_CATEGORIES_FAILED: {
      return {
        ...state,
        errorMessageGpc: action.error,
      };
    }
    case types.CLEAR_FETCHED_CATEGORIES: {
      return {
        ...state,
        fetchedCategories: [],
      };
    }
    case types.CATEGORY_SELECTED: {
      return {
        ...state,
        selectedCategory: action.payload,
      };
    }
    case sharedTypes.RECEIVE_CREATE_ITEM: {
      return {
        ...state,
        createItemResponse: action.payload,
      };
    }
    case sharedTypes.RECEIVE_CREATE_ITEM_FAILED: {
      return {
        ...state,
        errorMessage: action.error,
      };
    }
    case types.CLEAR_ERROR_MESSAGES: {
      return {
        ...state,
        errorMessage: {},
      };
    }
    case types.CLEAR_ITEM_CREATE_STATE: {
      return INITIAL_STATE;
    }
    // stage 2
    case types.SELECTED_TAGS: {
      if (Array.isArray(action.payload)) {
        return {
          ...state,
          selectedTags: [...action.payload],
        };
      }
      return {
        ...state,
        selectedTags: [...state.selectedTags, action.payload],
      };
    }
    case types.DELETE_TAG: {
      return {
        ...state,
        selectedTags: state.selectedTags.filter(
          (tag) => tag !== action.payload
        ),
      };
    }
    case types.ITEM_DISPLAY_NAME: {
      return {
        ...state,
        itemDisplayName: action.payload,
      };
    }
    case types.ITEM_DESCRIPTION: {
      return {
        ...state,
        itemDescription: action.payload,
      };
    }
    case types.GTIN: {
      return {
        ...state,
        gtin: action.payload,
      };
    }
    case types.SHARE_DATA: {
      return {
        ...state,
        shareData: action.payload,
      };
    }
    case types.SERIAL_NUMBER: {
      return {
        ...state,
        serialNumber: action.payload,
      };
    }
    case sharedTypes.UPLOAD_COVER_PHOTO: {
      return {
        ...state,
        uploadCoverPhotoLoading: true,
      };
    }
    case sharedTypes.RECEIVE_UPLOAD_COVER_PHOTO: {
      return {
        ...state,
        uploadCoverPhotoLoading: false,
        coverPhotoLink: action.payload.rawLink,
      };
    }
    case sharedTypes.EDIT_COVER_PHOTO: {
      return {
        ...state,
        coverPhotoLink: "",
      };
    }
    case sharedTypes.RECEIVE_UPLOAD_COVER_PHOTO_FAILED: {
      return {
        ...state,
        uploadCoverPhotoLoading: false,
        errorUploadImage: action.error.message,
      };
    }
    case sharedTypes.CLEAR_COVER_UPLOAD_ERRORS: {
      return {
        ...state,
        errorUploadImage: "",
      };
    }

    case sharedTypes.UPLOAD_DISPLAY_PICTURE: {
      return {
        ...state,
        uploadDisplayPictureLoading: true,
      };
    }
    case sharedTypes.RECEIVE_UPLOAD_DISPLAY_PICTURE: {
      return {
        ...state,
        uploadDisplayPictureLoading: false,
        displayPictureLink: action.payload.rawLink,
      };
    }
    case sharedTypes.RECEIVE_UPLOAD_DISPLAY_PICTURE_FAILED: {
      return {
        ...state,
        uploadDisplayPictureLoading: false,
        erroruploadDisplayPicture: action.error.message,
      };
    }
    case sharedTypes.CLEAR_DISPLAY_UPLOAD_ERRORS: {
      return {
        ...state,
        erroruploadDisplayPicture: "",
      };
    }
    case sharedTypes.EDIT_DISPLAY_PICTURE: {
      return {
        ...state,
        displayPictureLink: "",
      };
    }
    case types.SET_COVER_PHOTO: {
      return {
        ...state,
        coverPhotoLink: action.payload,
      };
    }
    case types.SET_DISPLAY_PICTURE: {
      return {
        ...state,
        displayPictureLink: action.payload,
      };
    }
    case types.CLEAR_TEMPLATE: {
      return {
        ...state,
        selectedTags: [],
        itemDisplayName: "",
        itemDescription: "",
        coverPhotoLink: "",
        displayPictureLink: "",
        erroruploadDisplayPicture: "",
        errorUploadImage: "",
      };
    }
    case types.SET_ITEM_CREATE: {
      return {
        ...state,
        itemCreate: action.payload,
      };
    }
    default:
      return state;
  }
};

export default inventoryCreateItemReducer;
