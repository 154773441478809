import types from "./types";
import sharedTypes from "api/item-detail/types";

const INITIAL_STATE = {
  initPedigree: [],
  pedigree: [],
  modifiedTree: false,
  currentItem: {},
  ownersData: [],
  loadingItem: false,
  errorMessageItemDetails: {},
};

const shareItemReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case sharedTypes.FETCH_ITEM_DETAIL: {
      return {
        ...state,
        loadingItem: true,
      };
    }
    case sharedTypes.RECEIVE_ITEM_DETAIL: {
      return {
        ...state,
        currentItem: action.payload,
        loadingItem: false,
      };
    }
    case sharedTypes.RECEIVE_ITEM_DETAIL_FAILED: {
      return {
        ...state,
        errorMessageItemDetails: action.error,
        loadingItem: false,
      };
    }
    case types.SHARE_ADD_PEDIGREE: {
      return {
        ...state,
        pedigree: action.payload,
        modifiedTree: true,
      };
    }
    case types.SHARE_ADD_INIT_PEDIGREE: {
      return {
        ...state,
        initPedigree: action.payload,
      };
    }
    case types.SHARE_OWNERS_DATA: {
      return {
        ...state,
        ownersData: action.payload,
      };
    }
    case types.LOADING_ITEM: {
      return {
        ...state,
        loadingItem: action.payload,
      };
    }
    case types.CLEAR_STATE: {
      return {
        ...state,
        initPedigree: [],
        pedigree: [],
        modifiedTree: false,
        ownersData: [],
        errorMessageItemDetails: {},
        isItemLoaded: false,
      };
    }
    case types.CLEAR_SHARE_ITEM_STATE: {
      return {
        ...state,
        initPedigree: [],
        pedigree: [],
        modifiedTree: false,
        ownersData: [],
        errorMessageItemDetails: {},
      };
    }
    default:
      return state;
  }
};

export default shareItemReducer;
