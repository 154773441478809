const CATEGORY_SELECTED = "CATEGORY_SELECTED";
const CLEAR_FETCHED_CATEGORIES = "CLEAR_FETCHED_CATEGORIES";
const CLEAR_ITEM_CREATE_STATE = "CLEAR_ITEM_CREATE_STATE";
const CLEAR_ERROR_MESSAGES = "CLEAR_ERROR_MESSAGES";
const ITEM_DISPLAY_NAME = "ITEM_DISPLAY_NAME";
const SELECTED_TAGS = "SELECTED_TAGS";
const DELETE_TAG = "DELETE_TAG";
const ITEM_DESCRIPTION = "ITEM_DESCRIPTION";
const CLEAR_TEMPLATE = "CLEAR_TEMPLATE";
const SET_COVER_PHOTO = "SET_COVER_PHOTO";
const SET_DISPLAY_PICTURE = "SET_DISPLAY_PICTURE";
const SET_ITEM_CREATE = "SET_ITEM_CREATE";
const GTIN = "GTIN";
const SHARE_DATA = "SHARE_DATA";
const SERIAL_NUMBER = "SERIAL_NUMBER";

export default {
  CATEGORY_SELECTED,
  CLEAR_FETCHED_CATEGORIES,
  CLEAR_ITEM_CREATE_STATE,
  CLEAR_ERROR_MESSAGES,
  SELECTED_TAGS,
  ITEM_DISPLAY_NAME,
  DELETE_TAG,
  ITEM_DESCRIPTION,
  CLEAR_TEMPLATE,
  SET_COVER_PHOTO,
  SET_DISPLAY_PICTURE,
  SET_ITEM_CREATE,
  GTIN,
  SHARE_DATA,
  SERIAL_NUMBER,
};
