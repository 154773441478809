const UPDATE_SEARCH_RESULT = "UPDATE_SEARCH_RESULT";
const UPDATE_PENDING_SEARCH_RESULT = "UPDATE_PENDING_SEARCH_RESULT";

const UPDATE_RECENTLY_CREATED_ITEMS = "UPDATE_RECENTLY_CREATED_ITEMS";

const SET_EXPANDED_PANEL = "SET_EXPANDED_PANEL";

const CLEAR_INVENTORY_STATE = "CLEAR_INVENTORY_STATE";

const LOADING_INVENTORY = "LOADING_INVENTORY";
const LOADING_PENDING_INVENTORY = "LOADING_PENDING_INVENTORY";

const FILTERING_SORTING_INVENTORY = "FILTERING_SORTING_INVENTORY";
const FILTERING_SORTING_PENDING_INVENTORY =
  "FILTERING_SORTING_PENDING_INVENTORY";

const CLEAR_ERROR_MESSAGES = "CLEAR_ERROR_MESSAGES";

const SELECTED_ITEMS = "SELECTED_ITEMS";
const CLEAN_SELECTED_ITEMS = "CLEAN_SELECTED_ITEMS";

export default {
  UPDATE_SEARCH_RESULT,
  UPDATE_PENDING_SEARCH_RESULT,

  UPDATE_RECENTLY_CREATED_ITEMS,

  SET_EXPANDED_PANEL,

  CLEAR_INVENTORY_STATE,
  LOADING_INVENTORY,
  LOADING_PENDING_INVENTORY,

  FILTERING_SORTING_INVENTORY,
  FILTERING_SORTING_PENDING_INVENTORY,

  CLEAR_ERROR_MESSAGES,

  SELECTED_ITEMS,
  CLEAN_SELECTED_ITEMS,
};
