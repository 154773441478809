/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

export function BreadCrumbs({ items }) {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  if (!items || !items.length) {
    return "";
  }

  const getTitle = (title) => {
    if (title === "Map") {
      return "Business Data Analytics";
    } else if (title === "Data Exchange") {
      return "Blockchain Data Interchange";
    }
    return title;
  };

  const checkRenderExtra = (title) => {
    if (
      ["Local Twins", "Global Twins"].includes(title) &&
      (pathname.includes("/item/") || pathname.includes("/merge-items/"))
    ) {
      return true;
    }
    if (title === "BDI Agreements") {
      if (pathname === "/agreements") {
        return false;
      } else {
        return true;
      }
    }
    return ["Map", "Data Exchange", "BDI Agreements"].includes(title);
  };

  const getExtra = (title) => {
    if (["Local Twins", "Global Twins", "BDI Agreements"].includes(title)) {
      if (pathname.includes("/item/") && pathname.includes("/split/")) {
        return "Split Twin";
      } else if (
        pathname.includes("/item/") &&
        pathname.includes("/transfer")
      ) {
        return "Transfer Twin Ownership";
      } else if (pathname.includes("/item/") && pathname.includes("/share")) {
        return "Share Digital Product Passport";
      } else if (pathname.includes("/merge-items")) {
        return "Merge Twins";
      } else if (pathname.includes("/item/")) {
        return "Twin Detail";
      } else if (pathname.includes("/agreements/new")) {
        return "New Agreement";
      } else if (pathname.includes("/agreements/details")) {
        return "Details";
      }
    }
    return title;
  };

  return (
    <ul className="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2">
      <li
        className="breadcrumb-item"
        key={"breadcrumb-item-key"}
        style={{ display: "inline-flex" }}
      >
        <Link className="text-muted" to="/dashboard">
          {t("product-provenance")}
        </Link>
      </li>
      {items.map((item, index) => (
        <React.Fragment key={`breadcrumbs-list-${index}`}>
          <li
            key={`bc-${item.pathname}-${index}`}
            className="breadcrumb-item"
            style={{ display: "inline-flex" }}
          >
            <Link className="text-muted" to={{ pathname: item.pathname }}>
              {getTitle(item.title)}
            </Link>
          </li>
          {checkRenderExtra(item.title) && (
            <li key={`bc-${item.title}-${index}`} className="breadcrumb-item">
              <span className="text-muted">{getExtra(item.title)}</span>
            </li>
          )}
        </React.Fragment>
      ))}
    </ul>
  );
}
