/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Dropdown } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { DropdownTopbarItemToggler } from "../../../_metronic/_partials/dropdowns";
import { makeStyles, Typography } from "@material-ui/core";
import { capitalizeFirst } from "../utils/helpers";
import { useTranslation } from "react-i18next";

export function UbnSelectorDropdown({ selectedUbn, pressedUbn, allUbns }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Dropdown drop="down" align="right">
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-my-cart"
      >
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="language-panel-tooltip">
              {t("select-department")}
            </Tooltip>
          }
        >
          <div>
            <Typography className={classes.text} variant="h6">
              {capitalizeFirst(selectedUbn.name)}
            </Typography>
          </div>
        </OverlayTrigger>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-left dropdown-menu-anim dropdown-menu-top-unround">
        <ul className="navi navi-hover py-4">
          {allUbns.map((u, i) => {
            if (!u.blocked) {
              return (
                <div
                  onClick={() => pressedUbn(u)}
                  className={classes.row}
                  key={`${u.name}${i}`}
                >
                  <p style={{ margin: 0, padding: 0 }}>
                    {capitalizeFirst(u.name)}
                  </p>
                </div>
              );
            } else {
              return null;
            }
          })}
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  );
}

const useStyles = makeStyles(() => ({
  row: {
    cursor: "pointer",
    padding: "1rem",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.05)",
    },
  },
  text: {
    cursor: "pointer",
    textDecorationLine: "underline",
  },
}));
