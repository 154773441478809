/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { permissionActions } from "api/APIService";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import ApiStorage from "./ApiStorage";
import RedirectPage from "./pages/RedirectPage";
import RedirectBDIPage from "./pages/RedirectBDIPage";
import ChangeUrl from "./pages/ChangeUrl";
import { isObjectEmpty } from "api/utils/utils";

export function Routes(props) {
  /*const {isAuthorized} = useSelector(
        ({auth}) => ({
            isAuthorized: auth.user != null,
        }),
        shallowEqual
    );*/
  const dispatch = useDispatch();

  // needed for component update
  // eslint-disable-next-line no-unused-vars
  const { loginRes, logoutFlag, services } = useSelector(
    (state) => ({
      loginRes: state.login.loginRes,
      logoutFlag: state.login.logoutFlag,
      services: state.permissions.services,
    }),
    shallowEqual
  );

  // Check that PRP is activated
  // TODO: Refactor this
  let PRPActivated = true;
  const subs = JSON.parse(ApiStorage.getSessionToken())?.userProfile
    ?.subscriptions;
  if (subs && subs.find((s) => s.type === "UBN" && s.status === "ACTIVE")) {
    PRPActivated = true;
  }

  useEffect(() => {
    if (isObjectEmpty(services)) {
      dispatch(permissionActions.getServices());
    }
  }, [services, dispatch]);

  return (
    <Switch>
      <Route exact path="/auth/change-url" component={ChangeUrl} />
      <Route exact path="/auth/redirect-page" component={RedirectPage} />
      <Route exact path="/auth/redirect-bdi-page" component={RedirectBDIPage} />
      <Route exact path="/auth/login" component={AuthPage} />

      {(!PRPActivated ||
        ApiStorage.getSessionToken() === null ||
        JSON.parse(ApiStorage.getSessionToken()).authToken === undefined) &&
      window.location.pathname !== "/auth/redirect-page" ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />

      {(ApiStorage.getSessionToken() === null ||
        JSON.parse(ApiStorage.getSessionToken()).authToken === undefined) &&
      window.location.pathname !== "/auth/redirect-page" ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  );
}
