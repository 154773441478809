import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";

// app specific

import inventoryReducer from "../app/pages/inventory-page/duck";
import inventoryCreateItemReducer from "../app/pages/inventory-create-item/duck";
import dashboardReducer from "api/statistics/reducers";
import itemDetailsReducer from "../app/pages/item-details-page/duck";
import itemMergeSplitReducer from "api/item-merge-split/reducers";
import messagesReducer from "api/bdi-messages/reducers";
import transferItemReducer from "../app/pages/transfer-item-details-page/duck";
import shareItemReducer from "../app/pages/share-item-details-page/duck";
import globalItemsReducer from "api/inventory/globalItemsReducer";
import loginReducer from "api/login/reducers";
import templateLibraryReducer from "api/template-library/reducers";
import locationsReducer from "api/big-data-intelligence/reducers";
import permissionsReducer from "api/permissions/reducers";
import itemCreateReducer from "api/item-create/reducers";
import accountManagementReducer from "api/account-management/reducers";

import { itemCreateSagas } from "api/item-create/sagas";
import { statisticsSagas } from "api/statistics/sagas";
import { itemDetailsSagas } from "../app/pages/item-details-page/duck/sagas";
import { itemMergeSplitSagas } from "api/item-merge-split/sagas";
import { messagesSagas } from "api/bdi-messages/sagas";
import { loginSagas } from "api/login/sagas";
import { itemsSagas } from "api/inventory/sagas";
import { itemDetailSagas } from "api/item-detail/sagas";
import { templateLibrarySagas } from "api/template-library/sagas";
import { locationsSagas } from "api/big-data-intelligence/sagas";
import { permissionsSagas } from "api/permissions/sagas";
import { accountManagementSagas } from "api/account-management/sagas";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  inventoryData: inventoryReducer,
  inventoryCreateItemData: inventoryCreateItemReducer,
  dashboard: dashboardReducer,
  itemDetails: itemDetailsReducer,
  itemMergeSplit: itemMergeSplitReducer,
  messages: messagesReducer,
  transferItem: transferItemReducer,
  globalItems: globalItemsReducer,
  shareItem: shareItemReducer,
  login: loginReducer,
  templateLibrary: templateLibraryReducer,
  itemsLocations: locationsReducer,
  permissions: permissionsReducer,
  globalItemCreate: itemCreateReducer,
  accountManagement: accountManagementReducer,
});

export function* rootSaga() {
  yield all([
    auth.saga(),
    ...itemCreateSagas,
    ...statisticsSagas,
    ...itemDetailsSagas,
    ...messagesSagas,
    ...itemMergeSplitSagas,
    ...itemsSagas,
    ...itemDetailSagas,
    ...loginSagas,
    ...templateLibrarySagas,
    ...locationsSagas,
    ...permissionsSagas,
    ...accountManagementSagas,
  ]);
}
