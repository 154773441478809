import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

import ApiStorage from "../ApiStorage";
import APIService from "api/APIService";
const Loading = (loading) => {
  return <ClipLoader size={40} color={"#CFAF4E"} loading={loading} />;
};
const RedirectPage = (props) => {
  React.useEffect(() => {
    const queryParams = new URLSearchParams(props.location.search);
    const params = {
      redirectPath: queryParams.get("redirectPath"),
      backendUri: queryParams.get("backendUri"),
      userId: queryParams.get("userId"),
      authToken: queryParams.get("authToken"),
      name: queryParams.get("name"),
    };
    if (!params.redirectPath) {
      params.redirectPath = "/dashboard";
    }
    if (params.userId) {
      const formattedParams = new URLSearchParams({
        userId: params.userId,
      }).toString();
      params.redirectPath = `${params.redirectPath}?${formattedParams}`;
    }
    if (params.authToken) {
      fetch(new URL(`${APIService.getEnvironment().ID}users/profile`), {
        method: "GET",
        headers: {
          authorization: `Bearer ${params.authToken}`,
        },
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw Error(`Request rejected with status ${res.status}`);
          }
        })
        .then((result) => {
          const jsonParams = {
            authToken: params.authToken,
            userProfile: result,
          };

          if (params.backendUri) {
            jsonParams.backend = { backendUri: params.backendUri };
          } else {
            if (params.name) {
              const endpoint = result.endpoints.find(
                (endpoint) => endpoint.name === params.name
              );
              if (endpoint) {
                jsonParams.backend = { backendUri: endpoint.backendUri };
              }
            } else {
              // if no backendUri or name in query params get first UBN uri
              const endpoint = result.endpoints.find(
                (endpoint) => endpoint.service === "UBN"
              );
              if (endpoint) {
                jsonParams.backend = { backendUri: endpoint.backendUri };
              }
            }
          }

          const jsonValue = JSON.stringify(jsonParams);

          return ApiStorage.setSessionToken(jsonValue);
        })
        .then(() => {
          props.history.push(params.redirectPath);
        });
    } else {
      props.history.push(params.redirectPath);
    }
  }, [props.history, props.location.search]);
  return <Loading loading={true} />;
};

export default RedirectPage;
