import types from "./types";
import sharedTypes from "api/inventory/types";

const INITIAL_STATE = {
  inventoryData: [],
  recentCreatedItems: [],
  pendingInventoryData: [],
  receiveConsolidate: {},
  pendingItemsLength: "",
  searchResult: [],
  pendingSearchResult: [],
  expandedPanelList: [],
  accept: false,
  isEmptyResponse: false,
  errorMessage: {},
  errorMessageData: {},
  errorMessagePendingData: {},
  loadingItem: false,
  loadingPendingItem: false,
  inventoryPaginationCount: 1,
  pendingInventoryPaginationCount: 1,
  filteringSortingPendingInventory: false,
  filteringSortingInventory: false,
  selectedItems: {},
  deployments: [],
};

const inventoryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.LOADING_INVENTORY: {
      return {
        ...state,
        loadingItem: true,
      };
    }
    case types.LOADING_PENDING_INVENTORY: {
      return {
        ...state,
        loadingPendingItem: true,
      };
    }
    case types.CLEAR_INVENTORY_STATE: {
      return {
        ...state,
        searchResult: [],
        pendingSearchResult: [],
        receiveConsolidate: {},
        expandedPanelList: [],
        accept: false,
        isEmptyResponse: false,
        errorMessage: {},
        errorMessageData: {},
        errorMessagePendingData: {},
      };
    }
    case sharedTypes.RESET_CONSOLIDATE: {
      return {
        ...state,
        receiveConsolidate: {},
      };
    }
    case sharedTypes.RECEIVE_INVENTORY_ITEMS: {
      let inventoryData = action.payload.data;
      inventoryData.map((oneItem) => {
        const rootUpdates = oneItem.data.filter(
          (dt) => dt.dataType === "root-update"
        );
        if (rootUpdates.length > 0) {
          const sorted = rootUpdates.sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
          oneItem["classification"] = sorted[0].data.values[0].value;
        }
        return oneItem;
      });
      const filter = action.filter;

      let recentCreatedItems;
      if (inventoryData.length > 5) {
        recentCreatedItems = inventoryData.slice(0, 5);
      } else {
        recentCreatedItems = inventoryData;
      }
      if (state.expandedPanelList.length > 0) {
        return {
          ...state,
          loadingItem: false,
        };
      }

      if (filter.length > 0) {
        return {
          ...state,
          inventoryData: inventoryData.filter((dt) =>
            dt.data.find(
              (dt) => dt.dataType === "category" && dt.context === filter
            )
          ),
          inventoryPaginationCount: action.payload["pages"],
          recentCreatedItems: recentCreatedItems,
          loadingItem: false,
        };
      }

      return {
        ...state,
        inventoryData,
        inventoryPaginationCount: action.payload["pages"],
        recentCreatedItems: recentCreatedItems,
        loadingItem: false,
      };
    }
    case sharedTypes.RECEIVE_INVENTORY_ITEMS_FAILED: {
      return {
        ...state,
        errorMessageData: action.payload,
        loadingItem: false,
      };
    }
    case sharedTypes.RECEIVE_PENDING_ITEMS: {
      let pendingInventoryData = action.payload.data;
      pendingInventoryData.map((oneItem) => {
        const rootUpdates = oneItem.data.filter(
          (dt) => dt.dataType === "root-update"
        );
        if (rootUpdates.length > 0) {
          const sorted = rootUpdates.sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
          oneItem["classification"] = sorted[0].data.values[0].value;
        }
        return oneItem;
      });

      const filter = action.filter;

      pendingInventoryData.forEach((data) => (data.accept = ""));

      if (state.expandedPanelList.length > 0) {
        return {
          ...state,
          loadingPendingItem: false,
        };
      }

      if (filter.length > 0) {
        return {
          ...state,
          pendingInventoryData: pendingInventoryData.filter((dt) =>
            dt.data.find(
              (dt) => dt.dataType === "category" && dt.context === filter
            )
          ),
          pendingInventoryPaginationCount: action.payload["pages"],
          pendingItemsLength: action.payload.data.length, // used for the top bar navigation notifications
          loadingPendingItem: false,
        };
      }

      return {
        ...state,
        pendingInventoryData,
        pendingInventoryPaginationCount: action.payload["pages"],
        pendingItemsLength: action.payload.data.length, // used for the top bar navigation notifications
        loadingPendingItem: false,
      };
    }
    case sharedTypes.RECEIVE_PENDING_ITEMS_FAILED: {
      return {
        ...state,
        loadingPendingItem: false,
        errorMessagePendingData: action.payload,
      };
    }
    case sharedTypes.CONSOLIDATE: {
      return {
        ...state,
        accept: action.payload.accept,
      };
    }
    // accept or decline pending item
    case sharedTypes.RECEIVE_CONSOLIDATE: {
      // go through existing pending data and change accept state
      const pendingData = state.pendingInventoryData.map((item) => {
        if (item._id === action.payload.itemId) {
          return { ...item, accept: state.accept };
        }
        return item;
      });
      return {
        ...state,
        receiveConsolidate: action.payload,
        pendingInventoryData: pendingData,
      };
    }
    case sharedTypes.RECEIVE_CONSOLIDATE_FAILED: {
      return {
        ...state,
        errorMessage: action.error,
      };
    }
    case types.CLEAR_ERROR_MESSAGES: {
      if (state.expandedPanelList.length > 0) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        errorMessageData: {},
        errorMessagePendingData: {},
      };
    }

    // === application specific
    case types.UPDATE_SEARCH_RESULT: {
      const searchResult = action.payload;
      return {
        ...state,
        searchResult,
      };
    }
    case types.UPDATE_PENDING_SEARCH_RESULT: {
      const pendingSearchResult = action.payload;
      return {
        ...state,
        pendingSearchResult,
      };
    }
    // append just created new item to the list of existing items
    case types.UPDATE_RECENTLY_CREATED_ITEMS: {
      return {
        ...state,
        recentCreatedItems: [...state.recentCreatedItems, action.payload],
      };
    }
    case types.SET_EXPANDED_PANEL: {
      if (action.payload) {
        return {
          ...state,
          expandedPanelList: [...state.expandedPanelList, action.payload],
        };
      } else {
        const arr = [...state.expandedPanelList];
        arr.pop();
        return {
          ...state,
          expandedPanelList: arr,
        };
      }
    }
    case types.FILTERING_SORTING_PENDING_INVENTORY: {
      return {
        ...state,
        filteringSortingPendingInventory: action.payload,
      };
    }
    case types.FILTERING_SORTING_INVENTORY: {
      return {
        ...state,
        filteringSortingInventory: action.payload,
      };
    }
    case types.SELECTED_ITEMS: {
      const item = action.payload.item;
      const isChecked = action.payload.isChecked;

      if (isChecked) {
        return {
          ...state,
          selectedItems: { ...state.selectedItems, [item._id]: item },
        };
      } else {
        if (item._id in state.selectedItems) {
          const items = state.selectedItems;
          delete items[item._id];
          return {
            ...state,
            selectedItems: { ...items },
          };
        }

        return state;
      }
    }
    case types.CLEAN_SELECTED_ITEMS: {
      return {
        ...state,
        selectedItems: {},
      };
    }
    case sharedTypes.RECEIVE_DEPLOYMENTS: {
      return {
        ...state,
        deployments: action.payload
          .filter((depl) => depl.service === "UBN")
          .map((dep) => dep.paymail),
      };
    }

    default:
      return state;
  }
};

export default inventoryReducer;
