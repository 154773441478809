import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

const Loading = (loading) => {
  return <ClipLoader size={40} color={"#CFAF4E"} loading={loading} />;
};
const RedirectBDIPage = (props) => {
  React.useEffect(() => {
    const queryParams = new URLSearchParams(props.location.search);

    const formattedParams = new URLSearchParams({
      redirectPath: queryParams.get("redirectPath"),
    }).toString();
    props.history.push(`/auth/login?${formattedParams}`);
  }, [props.history, props.location.search]);
  return <Loading loading={true} />;
};

export default RedirectBDIPage;
