import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

import ApiStorage from "../ApiStorage";

const Loading = (loading) => {
  return <ClipLoader size={40} color={"#CFAF4E"} loading={loading} />;
};
const ChangeUrl = (props) => {
  React.useEffect(() => {
    const queryParams = new URLSearchParams(props.location.search);
    const params = {
      newUrl: queryParams.get("newUrl"),
    };
    if (!params.redirectPath) {
      params.redirectPath = "/dashboard";
    }
    if (params.newUrl) {
      let storedItems = {
        newUrl: params.newUrl,
      };
      if (ApiStorage.getSessionToken()) {
        let oldItems = JSON.parse(ApiStorage.getSessionToken());
        if (oldItems.authToken) {
          storedItems["authToken"] = oldItems.authToken;
        }
        if (oldItems.userProfile) {
          storedItems["userProfile"] = oldItems.userProfile;
        }
      }

      const jsonValue = JSON.stringify(storedItems);
      ApiStorage.setSessionToken(jsonValue).then(() => {
        props.history.push(params.redirectPath);
      });
    } else {
      props.history.push(params.redirectPath);
    }
  }, [props.history, props.location.search]);
  return <Loading loading={true} />;
};

export default ChangeUrl;
