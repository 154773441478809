/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useTranslation } from "react-i18next";

import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import ApiStorage from "../../../../../app/ApiStorage";
import ApiService from "api/APIService";
import { isObjectEmpty } from "api/utils/utils";

export function AsideMenuList({ layoutProps }) {
  const { t } = useTranslation();
  const location = useLocation();

  const [profile, setProfile] = useState({});

  const getUserData = useCallback(async () => {
    try {
      const userData = await ApiStorage.getSessionToken();
      const transformedData = JSON.parse(userData);
      const { userProfile } = transformedData;
      setProfile(userProfile);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getUserData();
  }, [getUserData]);
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  const pressedSDC = async () => {
    try {
      const user = await ApiStorage.getSessionToken();
      const userJson = JSON.parse(user);
      if (userJson.userProfile) {
        const { authToken } = userJson;
        const { _id: userId } = userJson.userProfile;

        window.open(
          `${
            ApiService.getEnvironment().SDC
          }auth/redirect-page?userId=${userId}&authToken=${authToken}`
        );
      }
    } catch (e) {
      console.error(e, "<--- Error navigating to SDC");
    }
  };

  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">{t("my-workspace")}</span>
          </NavLink>
        </li>

        <li className="menu-section ">
          <h4 className="menu-text">{t("product-provenance")}</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>

        <li
          className={`menu-item menu-item-submenu`}
          aria-haspopup="true"
          data-menu-toggle="hover"
          data-cy="prp"
        >
          <div className="menu-link menu-toggle">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />
            </span>
            <span className="menu-text">GIM</span>
            <i className="menu-arrow" />
          </div>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <ul className="menu-subnav">
                <li
                  className="menu-item  menu-item-parent"
                  aria-haspopup="true"
                >
                  <span className="menu-link">
                    <span className="menu-text">GIM</span>
                  </span>
                </li>

                <li
                  className={`menu-item ${getMenuItemActive("/inventory")}`}
                  aria-haspopup="true"
                  data-cy="local-twins"
                >
                  <NavLink className="menu-link" to="/inventory">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">{t("local-twins")}</span>
                  </NavLink>
                </li>

                <li
                  className={`menu-item ${getMenuItemActive("/global-items")}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/global-items">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">{t("global-twins")}</span>
                  </NavLink>
                </li>

                <li
                  className={`menu-item ${getMenuItemActive("/item-groups")}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/item-groups">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">{t("item-groups")}</span>
                  </NavLink>
                </li>

                <li
                  className={`menu-item ${getMenuItemActive(
                    "/search-identifiers"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/search-identifiers">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">{t("search-identifiers")}</span>
                  </NavLink>
                </li>

                <li
                  className={`menu-item ${getMenuItemActive(
                    "/template-library"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/template-library">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">{t("templates")}</span>
                  </NavLink>
                </li>
              </ul>
            </ul>
          </div>
        </li>

        {
          <>
            {" "}
            {!isObjectEmpty(profile) &&
              ApiService.isCapable(
                profile,
                ApiService.capabilities.ubnBdaRead
              ) && (
                <>
                  <li className="menu-section ">
                    <h4 className="menu-text">
                      {t("business-data-analytics")}
                    </h4>
                    <i className="menu-icon flaticon-more-v2"></i>
                  </li>
                  <li
                    className={`menu-item menu-item-submenu`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <div className="menu-link menu-toggle">
                      <span className="svg-icon menu-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Layout/Globe.svg"
                          )}
                        />
                      </span>
                      <span className="menu-text">BDA</span>
                      <i className="menu-arrow" />
                    </div>
                    <div className="menu-submenu ">
                      <ul className="menu-subnav">
                        <ul className="menu-subnav">
                          <li
                            className="menu-item  menu-item-parent"
                            aria-haspopup="true"
                          >
                            <span className="menu-link">
                              <span className="menu-text">BDA</span>
                            </span>
                          </li>

                          <li
                            className={`menu-item ${getMenuItemActive(
                              "/business-data-analytics"
                            )}`}
                            aria-haspopup="true"
                          >
                            <NavLink
                              className="menu-link"
                              to="/business-data-analytics"
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">{t("map")}</span>
                            </NavLink>
                          </li>
                        </ul>
                      </ul>
                    </div>
                  </li>
                </>
              )}
            {!isObjectEmpty(profile) &&
              ApiService.isCapable(
                profile,
                ApiService.capabilities.ubnBdiRead
              ) && (
                <>
                  <li className="menu-section ">
                    <h4 className="menu-text">
                      {t("blockchain-data-interchange")}
                    </h4>
                    <i className="menu-icon flaticon-more-v2"></i>
                  </li>
                  <li
                    className={`menu-item menu-item-submenu`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <div className="menu-link menu-toggle">
                      <span className="svg-icon menu-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Mail.svg"
                          )}
                        />
                      </span>
                      <span className="menu-text">BDI</span>
                      <i className="menu-arrow" />
                    </div>
                    <div className="menu-submenu ">
                      <ul className="menu-subnav">
                        <ul className="menu-subnav">
                          <li
                            className="menu-item  menu-item-parent"
                            aria-haspopup="true"
                          >
                            <span className="menu-link">
                              <span className="menu-text">BDI</span>
                            </span>
                          </li>

                          <li
                            className={`menu-item ${getMenuItemActive(
                              "/messages"
                            )}`}
                            aria-haspopup="true"
                          >
                            <NavLink className="menu-link" to="/messages">
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">
                                {t("data-exchange")}
                              </span>
                            </NavLink>
                          </li>

                          <li
                            className={`menu-item ${getMenuItemActive(
                              "/agreements"
                            )}`}
                            aria-haspopup="true"
                          >
                            <NavLink className="menu-link" to="/agreements">
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">
                                {t("agreements")}
                              </span>
                            </NavLink>
                          </li>
                        </ul>
                      </ul>
                    </div>
                  </li>
                </>
              )}
            {!isObjectEmpty(profile) &&
              ApiService.isCapable(
                profile,
                ApiService.capabilities.ubnLcaRead
              ) && (
                <>
                  <li className="menu-section ">
                    <h4 className="menu-text">{t("lifecycle-assesment")}</h4>
                    <i className="menu-icon flaticon-more-v2"></i>
                  </li>
                  <li
                    className={`menu-item menu-item-submenu`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <div className="menu-link menu-toggle">
                      <span className="svg-icon menu-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Design/Edit.svg"
                          )}
                        />
                      </span>
                      <span className="menu-text">LCA</span>
                      <i className="menu-arrow" />
                    </div>
                    <div className="menu-submenu ">
                      <ul className="menu-subnav">
                        <ul className="menu-subnav">
                          <li
                            className="menu-item menu-item-parent"
                            aria-haspopup="true"
                          >
                            <span className="menu-link">
                              <span className="menu-text">LCA</span>
                            </span>
                          </li>

                          <li
                            className={`menu-item ${getMenuItemActive(
                              "/life-cycle-assessment/inputs"
                            )}`}
                            aria-haspopup="true"
                          >
                            <NavLink
                              className="menu-link"
                              to="/life-cycle-assessment/inputs"
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">{t("inputs")}</span>
                            </NavLink>
                          </li>

                          <li
                            className={`menu-item ${getMenuItemActive(
                              "/life-cycle-assessment/reports"
                            )}`}
                            aria-haspopup="true"
                          >
                            <NavLink
                              className="menu-link"
                              to="/life-cycle-assessment/reports"
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">{t("reports")}</span>
                            </NavLink>
                          </li>
                        </ul>
                      </ul>
                    </div>
                  </li>
                </>
              )}
            {!isObjectEmpty(profile) &&
              ApiService.isCapable(
                profile,
                ApiService.capabilities.sdcRead
              ) && (
                <>
                  <li className="menu-section ">
                    <h4 className="menu-text">
                      {t("secure-document-collaboration")}
                    </h4>
                    <i className="menu-icon flaticon-more-v2"></i>
                  </li>
                  <li
                    className={`menu-item menu-item-submenu`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <div className="menu-link menu-toggle">
                      <span className="svg-icon menu-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Design/Layers.svg"
                          )}
                        />
                      </span>
                      <span className="menu-text">SDC</span>
                      <i className="menu-arrow" />
                    </div>
                    <div className="menu-submenu ">
                      <ul className="menu-subnav">
                        <ul className="menu-subnav">
                          <li
                            className="menu-item  menu-item-parent"
                            aria-haspopup="true"
                          >
                            <span className="menu-link">
                              <span className="menu-text">SDC</span>
                            </span>
                          </li>

                          <li className={`menu-item`} aria-haspopup="true">
                            <div className="menu-link" onClick={pressedSDC}>
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">
                                {t("dashboard")}
                              </span>
                              <span className="svg-icon menu-icon">
                                <ExitToAppIcon />
                              </span>
                            </div>
                          </li>
                        </ul>
                      </ul>
                    </div>
                  </li>
                </>
              )}
          </>
        }
      </ul>
    </>
  );
}
