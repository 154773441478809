import { put, takeEvery } from "redux-saga/effects";
import sharedTypes from "api/item-detail/types";

//adding the data that is on the table to the backend (one by one)
export function* addDataToItem(action) {
  try {
    const itemId = action.payload.itemId;
    const addData = action.payload.data;

    for (const data of addData) {
      let params = {
        itemId,
        type: data[0].type ? "GeoJSON" : "senML",
        payload: data,
      };
      yield put({ type: sharedTypes.ADD_DATA, payload: params });
    }
    if (addData.length > 0) {
      yield put({ type: "ITEM_DETAILS_ADDED_LAST_DATA" });
    }
  } catch (e) {
    console.error(e);
  }
}

export const itemDetailsSagas = [
  takeEvery("ITEM_DETAILS_ADD_DATA_REQUEST", addDataToItem),
];
