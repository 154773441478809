import React from "react";

const InventoryLayout = ({
  title,
  buttons = null,
  actions = "",
  body,
  actionButton = false,
  expanded = true,
  subTitle = "",
}) => {
  return (
    <div className={`card card-custom gutter-b`} data-testid="inventory-layout">
      <div className="card-header align-items-center border-0 mt-4">
        {buttons}
        <h3 className="card-title align-items-start flex-column">
          <span className="font-weight-bolder text-dark">{title}</span>
          <span className="text-muted mt-3 font-weight-bold font-size-sm">
            {subTitle}
          </span>
        </h3>
        {actions && <div className="card-toolbar">{actions}</div>}
        {actionButton}
      </div>
      {expanded && <div className="card-body pt-4">{body}</div>}
    </div>
  );
};

export default InventoryLayout;
