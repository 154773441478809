import React, { useEffect, useState } from "react";
import { TextField, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import InventoryLayout from "../components/InventoryLayout";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { parseBarcode } from "gs1-barcode-parser-mod2";
import ApiService from "api/APIService";
import ItemsTable from "./ItemsTable";
import { isObjectEmpty } from "api/utils/utils";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import ModalComponent from "../components/ModalComponent";
import IconButton from "@material-ui/core/IconButton";
import CropFreeIcon from "@material-ui/icons/CropFree";
import { makeStyles } from "@material-ui/core/styles";

const SearchPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [textValue, setTextValue] = useState("");
  const [items, setItems] = useState({});
  const [scanedData, setScanedData] = useState("");
  // pagination
  const [paginationCount, setPaginationCount] = useState(1);
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (scanedData.length > 0) {
      setOpen(false);
      setTextValue(scanedData);
    }
  }, [scanedData]);

  useEffect(() => {
    if (!isObjectEmpty(items)) {
      handleSearch();
    }
  }, [page]);

  const handleChangePage = (page) => {
    setPage(page);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleTextValue = (event) => {
    setTextValue(event.target.value);
  };

  const checkGs1Barcodes = (code) => {
    // check if gs2 digital link
    if (code.startsWith("http")) {
      const splitCode = code.split("/");
      let path = "";
      for (let i = 3; i < splitCode.length; i = i + 2) {
        const str =
          i == 3
            ? splitCode[i] + "/" + splitCode[i + 1]
            : "/" + splitCode[i] + "/" + splitCode[i + 1];
        path = path.concat(str);
      }
      return [true, path];
    }
    if (code.includes("/")) {
      // partial digital link
      return [true, code];
    }
    // else check if gs1 barcodes
    try {
      const parsedInput = parseBarcode(code);
      const parsedItems = parsedInput.parsedCodeItems;
      let path = "";
      parsedItems.forEach((item, index) => {
        const str =
          index === 0
            ? item.ai + "/" + item.raw
            : "/" + item.ai + "/" + item.raw;
        path = path.concat(str);
      });

      return [true, path];
    } catch (e) {
      return [false, code];
    }
  };

  const normalizeInput = (scanedData) => {
    return scanedData.trim().replace(/\(/g, "").replace(/\)/g, "");
  };

  const handleSearch = async () => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    let codeString = textValue;
    if (textValue.length > 0 && scanedData.length === 0) {
      codeString = normalizeInput(textValue);
    }
    const [isGs1, identifier] = checkGs1Barcodes(codeString);

    if (isGs1) {
      try {
        const gs1Items = await ApiService.geTwinByGS1Identifier(
          { identifier: identifier, page },
          signal
        );
        if (gs1Items.data.length === 0) {
          // try if identifier
          try {
            const otherItems = await ApiService.geTwinByIdentifier(
              { identifier: encodeURIComponent(codeString), page },
              signal
            );
            setItems(otherItems);
            setPaginationCount(otherItems.pages);
            setTextValue("");
            setScanedData("");
          } catch (e) {
            console.error(e);
          }
        } else {
          setItems(gs1Items);
          setPaginationCount(items.pages);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        const otherItems = await ApiService.geTwinByIdentifier(
          { identifier: identifier, page },
          signal
        );
        setItems(otherItems);
        setPaginationCount(otherItems.pages);
        setTextValue("");
        setScanedData("");
      } catch (e) {
        console.error(e);
      }
    }
  };

  const body = (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
        {isObjectEmpty(items) && (
          <Typography variant="body1" className={classes.emptyItemsMarginTop}>
            {t(
              "start-searching-by-scanning-your-barcodes-or-type-in-value-from-barcodes"
            )}
          </Typography>
        )}
        {!isObjectEmpty(items) && items.data.length === 0 && (
          <Typography
            variant="body1"
            className={classes.emptyItemsMarginTop}
            style={{ color: "#D1583C" }}
          >
            {t("no-items-found-try-another-search")}
          </Typography>
        )}
      </Grid>

      <Grid item xs={12} sm={2}>
        <IconButton
          aria-label="scan"
          color="primary"
          onClick={handleOpenModal}
          style={{ width: "7rem", height: "7rem" }}
        >
          <CropFreeIcon style={{ width: "5rem", height: "5rem" }} />
        </IconButton>
      </Grid>
      <Grid item xs={12} sm={8}>
        <TextField
          onChange={(event) => handleTextValue(event)}
          id="standard-basic"
          label={t("type-in-barcode-value")}
          value={textValue}
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSearch}
          className="Search"
        >
          {t("search")}
        </Button>
      </Grid>

      {!isObjectEmpty(items) && items.data.length > 0 && (
        <Grid item xs={12} className={classes.emptyItemsMarginTop}>
          <ItemsTable
            items={items.data}
            changePage={handleChangePage}
            page={page}
            paginationCount={paginationCount}
          />
        </Grid>
      )}
    </Grid>
  );

  const scanCode = (
    <div style={{ textAlign: "center" }}>
      <BarcodeScannerComponent
        width={500}
        height={500}
        onUpdate={(err, result) => {
          if (result) setScanedData(result.text);
        }}
      />
      <p>{scanedData}</p>
    </div>
  );

  return (
    <>
      <InventoryLayout
        title={t("find-items-groups-or-jobs-by-identifier")}
        body={body}
        actions={<></>}
      />
      <ModalComponent
        open={open}
        handleClose={handleClose}
        dialogTitle={t("scan-barcode")}
        dialogContent={scanCode}
        maxWidth="md"
        dialogActions={
          <>
            <Button
              onClick={handleClose}
              color="secondary"
              className="cancel-button"
            >
              {t("close")}
            </Button>
          </>
        }
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  emptyItemsMarginTop: {
    marginTop: theme.spacing(2),
    color: "#48465b",
  },
}));

export default SearchPage;
