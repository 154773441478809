const tokenKeyName = "unisot- auth";
const tokenStorage = {
  cookie: "COOKIE",
  localStorage: "LOCAL_STORAGE",
  mobileStorage: "MOBILE_STORAGE",
};

// default for mobile
let tokenStorageType = tokenStorage.localStorage;

// token var
let sessionToken = undefined;

const setTokenStorageType = (storageType) => {
  tokenStorageType = storageType;
};

const setSessionToken = async (token) => {
  try {
    if (tokenStorageType === tokenStorage.cookie) {
      sessionStorage.setItem(tokenKeyName, token);
    }
    if (tokenStorageType === tokenStorage.localStorage) {
      localStorage.setItem(tokenKeyName, token);
    }
  } catch (err) {
    console.error(`Error save token in localStorage: ${err}`);
  }
};

const getSessionToken = () => {
  try {
    if (sessionToken) {
      // console.error('saved token')
      return sessionToken;
    } else {
      if (tokenStorageType === tokenStorage.cookie) {
        return sessionStorage.getItem(tokenKeyName);
      } else if (tokenStorageType === tokenStorage.localStorage) {
        return localStorage.getItem(tokenKeyName);
      }
    }
  } catch (err) {
    console.error(`Error get token from localStorage: ${err}`);
  }
};

const clearSessionToken = () => {
  sessionToken = undefined;
  try {
    if (tokenStorageType === tokenStorage.cookie) {
      sessionStorage.removeItem(tokenKeyName);
    } else if (tokenStorageType === tokenStorage.localStorage) {
      localStorage.removeItem(tokenKeyName);
    }
  } catch (err) {
    console.error(`Error remove token from localStorage: ${err}`);
  }
};

const getEnvStorage = async () => {
  return null
}

const logoutUser = () => {
  global.location.href = "/auth/login";
  //window.location.reload();
  clearSessionToken();
  //RootNavigation.navigate('Auth');
};

const ApiStorage = {
  tokenStorage,
  setTokenStorageType,
  setSessionToken,
  getSessionToken,
  clearSessionToken,
  logoutUser,
  getEnvStorage
};

export default ApiStorage;
