import types from "./types";
import sharedTypes from "api/item-detail/types";

const INITIAL_STATE = {
  initPedigree: [],
  pedigree: [],
  modifiedTree: false,
  currentItem: {},
  ownersData: [],
  transferId: "",
  showConfirmationModal: false,
  modifiedItem: [],
  loadingItem: false,
  transferDataLength: 0,
  errorMessageItemDetails: {},
  usersPermission: [],
  formData: {},
};

const transferItemReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case sharedTypes.FETCH_ITEM_DETAIL: {
      return {
        ...state,
        loadingItem: true,
      };
    }
    case sharedTypes.RECEIVE_ITEM_DETAIL: {
      return {
        ...state,
        currentItem: action.payload,
        loadingItem: false,
      };
    }
    case sharedTypes.RECEIVE_ITEM_DETAIL_FAILED: {
      return {
        ...state,
        errorMessageItemDetails: action.error,
        loading: false,
      };
    }
    case types.TRANSFER_ADD_PEDIGREE: {
      return {
        ...state,
        pedigree: action.payload,
        modifiedTree: true,
      };
    }
    case types.TRANSFER_ADD_INIT_PEDIGREE: {
      return {
        ...state,
        initPedigree: action.payload,
      };
    }
    case types.TRANSFER_OWNERS_DATA: {
      const ownersData = action.payload;
      return {
        ...state,
        ownersData,
      };
    }
    case sharedTypes.RECEIVE_TRANSFER_ITEM: {
      return {
        ...state,
        transferDataLength: state.pedigree.length,
      };
    }
    case types.CLEAR_TRANSFER_ITEM_STATE: {
      return {
        ...state,
        initPedigree: [],
        pedigree: [],
        modifiedTree: false,
        ownersData: [],
        transferId: "",
        showConfirmationModal: false,
        modifiedItem: [],
        errorMessageItemDetails: {},
        usersPermission: [],
      };
    }
    case types.SET_MODIFIED_ITEM: {
      return {
        ...state,
        modifiedItem: action.payload,
      };
    }
    case types.ADD_USER_PERMISSION: {
      return {
        ...state,
        usersPermission: [...state.usersPermission, action.payload],
      };
    }
    case types.REMOVE_USER_PERMISSION: {
      return {
        ...state,
        usersPermission: state.usersPermission.filter(
          (user) => user !== action.payload
        ),
      };
    }
    case types.SAVE_FORM_DATA: {
      return {
        ...state,
        formData: action.payload,
      };
    }

    default:
      return state;
  }
};

export default transferItemReducer;
