const SHARE_ADD_PEDIGREE = "SHARE_ADD_PEDIGREE";
const SHARE_ADD_INIT_PEDIGREE = "SHARE_ADD_INIT_PEDIGREE";

const SHARE_OWNERS_DATA = "SHARE_OWNERS_DATA";

const SHARE_MODAL_CONFIRMATION = "SHARE_MODAL_CONFIRMATION";

const CLEAR_SHARE_ITEM_STATE = "CLEAR_SHARE_ITEM_STATE";
const CLEAR_STATE = "CLEAR_STATE";

const LOADING_ITEM = "LOADING_ITEM";

export default {
  SHARE_ADD_PEDIGREE,
  SHARE_ADD_INIT_PEDIGREE,
  SHARE_OWNERS_DATA,
  SHARE_MODAL_CONFIRMATION,
  CLEAR_SHARE_ITEM_STATE,
  CLEAR_STATE,
  LOADING_ITEM,
};
