const TRANSFER_ADD_PEDIGREE = "TRANSFER_ADD_PEDIGREE";
const TRANSFER_ADD_INIT_PEDIGREE = "TRANSFER_ADD_INIT_PEDIGREE";

const TRANSFER_OWNERS_DATA = "TRANSFER_OWNERS_DATA";

const CLEAR_TRANSFER_ITEM_STATE = "CLEAR_TRANSFER_ITEM_STATE";

const LOADING_ITEM = "LOADING_ITEM";

const SET_MODIFIED_ITEM = "SET_MODIFIED_ITEM";

const ADD_USER_PERMISSION = "ADD_USER_PERMISSION";
const REMOVE_USER_PERMISSION = "REMOVE_USER_PERMISSION";
const SAVE_FORM_DATA = "SAVE_FORM_DATA";

export default {
  TRANSFER_ADD_PEDIGREE,
  TRANSFER_ADD_INIT_PEDIGREE,
  TRANSFER_OWNERS_DATA,
  CLEAR_TRANSFER_ITEM_STATE,
  LOADING_ITEM,
  SET_MODIFIED_ITEM,
  ADD_USER_PERMISSION,
  REMOVE_USER_PERMISSION,
  SAVE_FORM_DATA,
};
